import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Home from './views/Home';
import Help from './views/contactUs';
import Chatbot from './views/chatWithUs';
import ReactPixel from 'react-facebook-pixel';

if (process.env.REACT_APP_PIXEL && process.env.REACT_APP_PIXEL !== '') {
    const options = {
        autoConfig: true,
        debug: false,
    };
    ReactPixel.init(process.env.REACT_APP_PIXEL, options);
    ReactPixel.pageView();
    let event = process.env.REACT_APP_TITLE;
    ReactPixel.track(event, event);
}

const App = () => {
    return (
        <main className={`App theme ${process.env.REACT_APP_THEME}`}>
            <Helmet>
                <link rel="icon" type="image/x-icon" href="https://cdn.ticketmundo.live/landing-eventos/demo2/favicon.png"/>
                <meta name="theme-color" content="#ffffff" />
                {
                    process.env.REACT_APP_PRODUCTION === 'development' && <meta name="robots" content="noindex"/>
                }
                <title>Karol G 2024</title>

                <meta
                    name='title'
                    content={process.env.REACT_APP_TITLE}
                />
                <meta
                    name='description'
                    content={process.env.REACT_APP_META_DESC}
                />
                <meta
                    name='url'
                    content={process.env.REACT_APP_META_URL}
                />

                <meta
                    name='og:site_name'
                    property='og:site_name'
                    content={process.env.REACT_APP_META_URL}
                />
                <meta
                    name='og:title'
                    property='og:title'
                    content={process.env.REACT_APP_TITLE}
                />
                <meta
                    name='og:description'
                    property='og:description'
                    content={process.env.REACT_APP_META_DESC}
                />
                <meta
                    name='og:url'
                    property='og:url'
                    content={process.env.REACT_APP_META_URL}
                />
                <meta
                    name='og:image'
                    property='og:image'
                    content={process.env.REACT_APP_URL_IMAGES + 'banner-movil.jpg'}
                />

                <meta
                    name='twitter:card'
                    property='twitter:card'
                    content="summary"
                />
                <meta
                    name='twitter:site'
                    property='twitter:site'
                    content="@simgulary"
                />
                <meta
                    name='twitter:title'
                    property='twitter:title'
                    content={process.env.REACT_APP_TITLE}
                />
                <meta
                    name='twitter:description'
                    property='twitter:description'
                    content={process.env.REACT_APP_META_DESC}
                />
                <meta
                    name='twitter:url'
                    property='twitter:url'
                    content={process.env.REACT_APP_META_URL}
                />
                <meta
                    name='twitter:image'
                    property='twitter:image'
                    content={process.env.REACT_APP_URL_IMAGES + 'banner-movil.jpg'}
                />

                {/* <script
                    async
                    src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GTAG}`}
                ></script>
                <script>
                    {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', ${process.env.REACT_APP_GTAG});
                    `}
                </script> */}
                <html className={`theme ${process.env.REACT_APP_THEME}`}/>
            </Helmet>
            <Router>
                <Routes>
                    <Route exact path='/' element={<Home />} />
                    <Route path='/help/' element={<Help />} />
                    <Route path='/chatbot/' element={<Chatbot />} />
                    <Route path='/help/:item' element={<Help />} />
                </Routes>
            </Router>
        </main>
    );
};

export default App;
