import React from 'react';
import {
    ButtonLogin
} from "@TicketMundo/login-tm-library";

const FanBase = () => {
    return (
        <section className="ld--fanbase ld--card">
            <img src={`${process.env.REACT_APP_URL_IMAGES}merch.png`} alt="fanbase" />
            <div className='ld--fanbase_text'>
                <h2 className="ld--title">Únete a la comunidad fan de Karol G</h2>
                <p>
                    Be a part of the movement and be aware of every move we make! Whether is Tour Dates, new Merch, new Songs or Streaming sessions. Be the first to know and never miss the opportunity to rock and roll with us.
                </p>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <label className={`ld--btn`} style={{ background: `var(--color__secondary)` }}>
                        <span>Unirme</span>
                        <span style={{ display: 'none' }}><ButtonLogin /></span>
                    </label>'
                </div>
            </div>
        </section>
    );
};

export default FanBase;
