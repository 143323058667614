import React from 'react';
import { Instagram } from './iconSvg';
const Productoras = () => {
    return (
        <section className="ld--wrap-prods">
            <div className="ld--card">
                <h5>Produce</h5>
                <ul style={{ marginTop: '12px', maxHeight: `186px`, overflow: 'auto' }} className='px-0'>
                    <li className="produtore_list">
                        <div>
                            <img src="https://cdn.ticketmundo.live/karol-G-manana-sera-bonito-tour-2024/live-nation-logo.png" alt="live-nation-logo" />
                        </div>
                        <div>
                            <span className="span_productor">Live Nation</span>
                            <a className='flex-div' href="https://www.instagram.com/livenation/" rel="noreferrer" target="_blank" style={{ color: `var(--color__text)`, cursor: 'pointer' }}>
                                <Instagram />
                                livenation
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
        </section>
    );
};

export default Productoras;
