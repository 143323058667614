import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import ReactPlayer from 'react-player';

const Banner = (props) => {
    const { forceShow = true } = props;
    const pagination = {
        clickable: true,
        renderBullet: function (index, className) {
            return '<span class="' + className + ' buttonsGallery"></span>';
        }
    };
    return forceShow ? (
        <section className="ld--wrap-gallery">
            <div className="ld--card">
                <div className="ld--title">Galería</div>
                <div
                    id="newIndicators"
                    className="carousel slide"
                    // style={{ height: '382px', overflow: 'hidden' }}
                >
                    <Swiper
                        spaceBetween={10}
                        slidesPerView={1}
                        pagination={pagination}
                        navigation={true}
                        // autoplay={{ delay: 5000, disableOnInteraction: true }}
                        modules={[Autoplay, Pagination, Navigation]}

                        // breakpoints={{
                        //     768: {
                        //         slidesPerView: 2,
                        //         spaceBetween: 30
                        //     },
                        //     1024: {
                        //         slidesPerView: 2,
                        //         spaceBetween: 30
                        //     }
                        // }}
                    >
                        <SwiperSlide>
                            <ReactPlayer
                                className="react-player"
                                playing
                                width="100%"
                                height="540px"
                                controls={true}
                                url={ `${process.env.REACT_APP_URL_IMAGES}Karol-G-Caracas-2024-video.mp4` }
                                light={`${process.env.REACT_APP_URL_IMAGES}cover-video.jpeg`}
                            />
                        </SwiperSlide><SwiperSlide>
                            <img
                                className="imgGal"
                                src={`${process.env.REACT_APP_URL_IMAGES}karol-g.jpeg`}
                                alt=""
                            />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img
                                className="imgGal"
                                src={`${process.env.REACT_APP_URL_IMAGES}banner-desktop.jpeg`}
                                alt=""
                            />
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </section>
    ) : null;
};

export default Banner;
