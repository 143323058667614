import React from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';

const Store = ({ items = [] }) => {
    const pagination = {
        clickable: true,
        renderBullet: function (index, className) {
            return '<span class="' + className + ' buttonsGallery"></span>';
        }
    };

    console.log(items)
    return (
        <>
            <section className="ld--intro ld--card ld--card--mechat">
                <h2 className="ld--title">
                    Descubre la tienda de Karol G
                    <Link to='https://tiendademo.ticketmundo.com/' className='nimbus_link'>
                        Visitar
                    </Link>
                </h2>
                <Swiper
                    slidesPerView={2}
                    spaceBetween={10}
                    pagination={pagination}
                    navigation={true}
                    breakpoints={{
                        640: {
                            spaceBetween: 10,
                            slidesPerView: 1
                        },
                        768: {
                            spaceBetween: 10,
                            slidesPerView: 2
                        },
                        1024: {
                            spaceBetween: 10,
                            slidesPerView: 2
                        },
                        1200: {
                            spaceBetween: 10,
                            slidesPerView: 4
                        },

                    }}
                    autoplay={{ delay: 5000, disableOnInteraction: true }}
                    modules={[Autoplay, Pagination, Navigation]}
                >
                    {items.map((m, idx) => (
                        <SwiperSlide key={idx}>
                            <div className="img--container">
                                <img
                                    src={`${process.env.REACT_APP_URL_IMAGES}product-${m}.png`}
                                    alt={m}
                                />
                            </div>
                        </SwiperSlide>
                    ))}
                    <SwiperSlide>
                        <div className="img--container">
                            <img
                                src={`${process.env.REACT_APP_URL_IMAGES}product-1.png`}
                                alt={'1'}
                            />
                        </div>
                    </SwiperSlide>
                </Swiper>
                {/* <ul className="ld--metodo">
                    {metodos.map((m, idx) => (
                        <li key={idx}>
                            <h2 className="ld--metodo--title">{m.moneda}</h2>
                            <ul className="ul_metodo">
                                {
                                    m?.tipos.includes('Pago Movil') && <li>
                                        <div className='img--container'>
                                            <img src={'https://cdn.ticketmundo.live/commons/pm-c2p.svg'} alt="pm-c2p" />
                                        </div>
                                        Pago Movil
                                    </li>
                                }
                                {
                                    m?.tipos.includes('C2P') && <li>
                                        <div className='img--container'>
                                            <img src={'https://cdn.ticketmundo.live/commons/pm-c2p.svg'} alt="pm-c2p" />
                                        </div>
                                        C2P
                                    </li>
                                }
                                {
                                    m?.tipos.includes('BNC Debito') && <li>
                                        <div className='img--container'>
                                            <img src={'https://cdn.ticketmundo.live/commons/BNC.svg'} alt="credit-cards" />
                                        </div>
                                        BNC Debito
                                    </li>
                                }
                                {
                                    m?.tipos.includes('Mercantil') && <li>
                                        <div className='img--container'>
                                            <img src={'https://cdn.ticketmundo.live/commons/mercantil.png'} alt="mercantil" />
                                        </div>
                                        Mercantil
                                    </li>
                                }
                                {
                                    m?.tipos.includes('Banesco') && <li>
                                        <div className='img--container'>
                                            <img src={'https://cdn.ticketmundo.live/commons/banesco.png'} alt="banesco" />
                                        </div>
                                        Banesco
                                    </li>
                                }
                                {
                                    m?.tipos.includes('Tarjeta Credito') && <li>
                                        <div className='img--container'>
                                            <img src={'https://cdn.ticketmundo.live/commons/credit-cards.svg'} alt="banesco" />
                                        </div>
                                        Tarjeta Credito
                                    </li>
                                }
                                {
                                    m?.tipos.includes('Paypal') && <li>
                                        <div className='img--container'>
                                            <img src={'https://cdn.ticketmundo.live/commons/paypal.svg'} alt="paypal" />
                                        </div>
                                        Paypal
                                    </li>
                                }
                                {
                                    m?.tipos.includes('Zelle') && <li>
                                        <div className='img--container'>
                                            <img src={'https://cdn.ticketmundo.live/commons/zelle.png'} alt="Zelle" />
                                        </div>
                                        zelle
                                    </li>
                                }
                                {
                                    m?.tipos.includes('Efectivo') && <li>
                                        <div className='img--container'>
                                            <img src={'https://cdn.ticketmundo.live/commons/cash.svg'} alt="cash" />
                                        </div>
                                        Efectivo
                                    </li>
                                }
                            </ul>
                        </li>
                    ))}
                </ul> */}
            </section>
        </>
    );
};

export default Store;
