import React, { useState, useEffect } from 'react';
import { Share, Favourite /*, Search */ } from './iconSvg';
import ModalShare from './ModalShare';
import {
    /* ButtonLogin,
    ModalAuth, */
    useConfig,
    /* ModalSelectApp, */
    getLocalStorage,
    useLoginEvents
} from "@TicketMundo/login-tm-library";
// import axios from 'axios';

const Header = ({ urlBanner, urlBannerMovil }) => {
    const { setConfig } = useConfig();
    const { loginByToken } = useLoginEvents();
    const [urlShort] = useState(window.location.href);
    const [showShare, setShowShare] = useState(false);
    const handleShare = async () => {
        // const { status, data } = await axios.post(`Configuracion/AcortarUrl`, {
        //     url: window.location.href
        // });
        setShowShare(true);
    };
    useEffect(() => {
        setConfig({
            COMPANY_ID: 1,
            NAME: `Karol G Community`,
            autoCloseDrawer: false,
            activeDarkMode: true
        });
    }, []);
    useEffect(() => {
        window.addEventListener("load", () => {
            const userStorage = getLocalStorage("user");
            if (userStorage) return
            const tokenCookie = document.cookie.split("token=")[1]?.split(" ")[0]
            if (!tokenCookie) return;
            loginByToken(tokenCookie);
        })
    }, [])
    return (
        <header className="ld--header ">
            <div className="fixed-top">
                <div className="container-custom-tm">
                    <div className='header_kg'>
                        <a href="https://ticketmundo.com.ve/">
                            <img src={`${process.env.REACT_APP_URL_IMAGES}KG_Tour_Logo_x300.png`} alt="logo" style={{ width: 'auto', height: '50px' }}/>
                        </a>
                    </div>
                    {/* <div style={{ display: 'flex', gap: '10px', alignItems: 'center', fontSize: '22px' }}>
                        <Search />
                        |
                        <div className="btn_login_ajust">
                            <ButtonLogin/>
                            <ModalAuth />
                            <ModalSelectApp/>
                        </div>
                    </div> */}
                </div>
            </div>
            <figure className="urlBanner">
                <img src={urlBanner} alt="" />
            </figure>
            <figure className="urlBannerMovil">
                <img src={urlBannerMovil} alt="" />
            </figure>
            <div className="content-bottom">
                <div className="container-custom-tm">
                    <div className="wrap-button">
                        <button className="share" onClick={handleShare}>
                            <Share />
                        </button>
                        <button className="share" onClick={handleShare}>
                            <Favourite />
                        </button>
                    </div>
                </div>
            </div>
            <ModalShare
                linkFunc={urlShort}
                show={showShare}
                handleClose={() => {
                    setShowShare(false);
                }}
            />
        </header>
    );
};

export default Header;
