import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="ld--footer">
            <div className="container-custom-tm">
                <span className="flex-div">
                    <Link to='https://www.facebook.com/ticketmundo' target="_blank" className='ld--logo-tm my-2'>
                        <img src={`${process.env.REACT_APP_URL_IMAGES_COMMONS}facebook-ticketmundo.svg` } alt="facebook"/>
                    </Link>
                    <Link to='https://instagram.com/ticketmundo_ve' target="_blank" className='ld--logo-tm my-2'>
                        <img src={`${process.env.REACT_APP_URL_IMAGES_COMMONS}instagram-ticketmundo.svg` } alt="instagram"/>
                    </Link>
                    <Link to='https://twitter.com/Ticketmundo_ve' target="_blank" className='ld--logo-tm my-2'>
                        <img src={`${process.env.REACT_APP_URL_IMAGES_COMMONS}x-ticketmundo.svg` } alt="x"/>
                    </Link>
                    <Link to='https://www.youtube.com/@TicketmundoVe' target="_blank" className='ld--logo-tm my-2'>
                        <img src={`${process.env.REACT_APP_URL_IMAGES_COMMONS}youtube-ticketmundo.svg` } alt="youtube"/>
                    </Link>
                    <Link to='https://www.tiktok.com/@ticketmundo_ve' target="_blank" className='ld--logo-tm my-2'>
                        <img src={`${process.env.REACT_APP_URL_IMAGES_COMMONS}tiktok-ticketmundo.svg` } alt="tiktok"/>
                    </Link>
                </span>
                <span>
                    {/* <Link to="/help/" className="suport-btn">
                        Soporte al Cliente
                    </Link> */}
                    <Link to="/chatbot/" className="suport-btn">
                        Soporte al Cliente
                    </Link>
                </span>
                <img className="ld--logo-tm my-2" src={ `${process.env.REACT_APP_URL_IMAGES_COMMONS}logo-footer.svg`} alt="Ticketmundo"/>
            </div>
        </footer>
    );
};

export default Footer;
