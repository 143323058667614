import React, { useEffect, useRef, useState } from 'react';
// import ReactPlayer from 'react-player';
const Spotify = ({ Spotifyurl }) => {
    const [height, setHeight] = useState(630);
    const ref = useRef(null);
    useEffect(() => {
        window.addEventListener('resize', resizeH);
        return () => {
            window.removeEventListener('resize', resizeH);
        };
    }, []);
    useEffect(() => {
        resizeH();
    }, [ref.current]);
    const resizeH = () => {
        let gallery = document.getElementsByClassName('ld--wrap-gallery')[0];
        if (
            height !== gallery.getBoundingClientRect().height &&
            gallery.getBoundingClientRect().height > 400
        ) {
            setHeight(gallery.getBoundingClientRect().height);
        } else {
            setHeight(gallery.getBoundingClientRect().height);
        }
    };
    return (
        <section className="ld--wrap-spotify">
            <div className="col-12 d-flex flex-column">
                <iframe
                    src={`${Spotifyurl}&theme=0`}
                    ref={ref}
                    width="100%"
                    height={height}
                    frameBorder="0"
                    style={{ borderRadius: '8px' }}
                    allowFullScreen=""
                    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                ></iframe>
            </div>
        </section>
    );
};

export default Spotify;
