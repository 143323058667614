import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import App from './App';
import { TMLoginProvider, getLocalStorage } from "@TicketMundo/login-tm-library";

const container = document.getElementById('root');
const root = createRoot(container);

function loginCallback (user) {
    const storageToken = getLocalStorage("token");
    const token = storageToken?.Value;
    console.log("storageToken-------------------->", storageToken);
    console.log("token--------------------------->", token);
    if (token) {
        document.cookie = `token=${token} max-age=10; domain=ticketmundo.com`;
    }
    console.log("cookies--------------------------->", document.cookie.split(";"));
    sessionStorage.setItem("session", JSON.stringify(user));
}
function logoutCallback () {
    sessionStorage.clear();
    window.location.search = '';
}
root.render(
    <React.StrictMode>
        <TMLoginProvider loginCallback={loginCallback} logoutCallback={logoutCallback}>
            <Provider store={store}>
                <App />
            </Provider>
        </TMLoginProvider>
    </React.StrictMode>
);
