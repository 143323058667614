import React from 'react';

const Intro = () => {
    return (
        <>
            <section className="ld--intro ld--card">
                <h2 className="ld--title">MAÑANA SERA BONITO TOUR 2024</h2>
                <p>
                    Karol G es una de las cantantes más exitosas y populares del género urbano, con una trayectoria que abarca el reggaetón, el trap latino, el reggae y el sertanejo. Su nombre artístico proviene de su nombre real, Carolina Giraldo Navarro, y nació el 14 de febrero de 1991 en Medellín, Colombia. Desde muy joven mostró su pasión por la música y participó en varios concursos de talento, como el Factor Xs en 2006.
                    <br></br><br></br>
                    Su carrera profesional comenzó en 2010, cuando firmó un contrato con la discográfica Universal Music Latin Entertainment y lanzó su primer sencillo, “Dime tú”. Desde entonces, ha colaborado con artistas como Nicky Jam, J Balvin, Anuel AA, Ozuna, Bad Bunny, Daddy Yankee y Maluma, entre otros. Algunos de sus éxitos más conocidos son “Ahora me llama”, “Mi cama”, “Culpables”, “Secreto”, “Tusa”, “Bichota” y “Location”.
                </p>
            </section>
        </>
    );
};

export default Intro;
